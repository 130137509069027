<script>
	import { onMount, createEventDispatcher } from 'svelte'
	import WebViewer from '@pdftron/webviewer'
	import CollabClient from '@pdftron/collab-client'

	const dispatch = createEventDispatcher()
	// const host = 'localhost:3000'
	const host = 'cnc-collab.cncteile24.xyz'
	const s = host === 'localhost:3000' ? '' : 's'

	onMount(() => {
		const el = document.getElementById('viewer')
		WebViewer({ path: '/lib' }, el).then(async (instance) => {
			const client = new CollabClient({
				instance,
				url: `http${s}://${host}/collab`,
  			subscriptionUrl: `ws${s}://${host}/collab/subscribe`
			})

			const result = await fetch(`http${s}://${host}/api/login`, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({})
			})

			if (result.status === 200) {
				const { token } = await result.json()
				// await client.loginWithToken(token)
				const loginResult = await client.loginWithToken(token)
				// console.log(loginResult)
				dispatch('ready', { client })
			}
		})
	})
</script>

<div id="viewer"></div>

<style>
	#viewer {
		width: 100%;
		height: 100%;
	}
</style>
