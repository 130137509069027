<script>
	import WebViewer from './WebViewer.svelte'

	async function ready (e) {
		const instance = e.detail.client
		try {
			await instance.loadDocument('https://pdftron.s3.amazonaws.com/downloads/pl/PDFTRON_about.pdf', {
				documentId: 'uuid',
				filename: 'doc.pdf',
				isPublic: true
			})
		} catch (e) {}
	}
</script>

<WebViewer on:ready={ready}/>
